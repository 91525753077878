<template>
    <div>
        <div class="col-lg-12 mb-5">
            <div class="row">
                <div class="col-lg-10 vertical pl-0 pr-0">
                    <div class="card pl-0 pr-0 pt-0 pb-0 ">

                        <table style="width:100% ">
                            <tr>
                                <th class="text-primary">Bull Name</th>
                                <th class="text-primary">Bull No</th>
                                <th class="text-primary">Renumber</th>
                                <th class="text-primary">Breed</th>
                                <th class="text-primary">Dam's Yield</th>
                                <th class="text-primary">Year/Date</th>
                                <th class="text-primary">Location</th>
                                <th class="text-primary">Dose</th>
                            </tr>
                            <tr v-if="!loadingData && data.length === 0" class="text-center">
                                <td colspan="8" class="mt-3"> No data</td>
                            </tr>
                            <tr v-if="loadingData" class="text-center">
                                <td colspan="8" class="mt-3"> Please wait while fetching data...</td>
                            </tr>
                            <tr v-for="(item, i) in data" :key="i" @click="selectBull(item)" style="cursor: pointer">
                                <td>{{ item.bull_name }}</td>
                                <td>{{ item.reg_no }}</td>
                                <td>{{ item.renumber }}</td>
                                <td>{{ item.breed }}</td>
                                <td>{{ item.dam_yield }}</td>
                                <td>{{ item.production_date }}</td>
                                <td>{{ item.location }}</td>
                                <td>{{ item.dose }}</td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="mt-5" v-if="selectedItem">
                <div class="row">
                    <div class="col-12">
                        <h5 class="text-primary mt-2 mb-4 font-poppins-semibold">Selected Bull</h5>
                    </div>
                    <div class="col-lg-10 vertical pl-0 pr-0">
                        <div class="card pl-0 pr-0 pt-0 pb-0 ">

                            <table style="width:100% ">
                                <tr>
                                    <th class="text-primary">Bull Name</th>
                                    <th class="text-primary">Bull No</th>
                                    <th class="text-primary">Renumber</th>
                                    <th class="text-primary">Breed</th>
                                    <th class="text-primary">Dam's Yield</th>
                                    <th class="text-primary">Year/Date</th>
                                    <th class="text-primary">Location</th>
                                    <th class="text-primary">Dose</th>
                                </tr>
                                <tr v-if="!loadingData && data.length === 0" class="text-center">
                                    <td colspan="8" class="mt-3"> No data</td>
                                </tr>
                                <tr v-if="loadingData" class="text-center">
                                    <td colspan="8" class="mt-3"> Please wait while fetching data...</td>
                                </tr>
                                <tr>
                                    <td>{{ selectedItem.bull_name }}</td>
                                    <td>{{ selectedItem.reg_no }}</td>
                                    <td>{{ selectedItem.renumber }}</td>
                                    <td>{{ selectedItem.breed }}</td>
                                    <td>{{ selectedItem.dam_yield }}</td>
                                    <td>{{ selectedItem.production_date }}</td>
                                    <td>{{ selectedItem.location }}</td>
                                    <td>{{ selectedItem.dose }}</td>

                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <s-form @submit="addItem" ref="form">
                <div class="mt-5">

                    <div class="row">

                        <div class="col-lg-2 pl-0">
                            <validated-input label="Despatch to RSB" class="field-required" v-model="model.rsb_despatch" :rules="{required:true}"></validated-input>
                        </div>
                        <div class="col-lg-2">
                            <validated-input label="PTM" v-model="model.ptm" class="field-required" :rules="{required:true}"></validated-input>
                        </div>
                    </div>
                </div>
                <div class="mt-5">

                    <div class="row">
                        <div class="col-lg-2 pl-0">
                            <validated-input label="Position" class="field-required" v-model="model.position" :rules="{required:true}"></validated-input>
                        </div>
                        <div class="col-lg-2">
                            <validated-input label="Dose" v-model="model.dose" class="field-required" :rules="{required:true}"></validated-input>
                        </div>
                        <div class="col-lg-2">
                            <validated-input label="Container No" v-model="model.container_no" class="field-required" :rules="{required:true}"></validated-input>
                        </div>
                        <div class="col-lg-2">
                            <validated-input label="Location" class="field-required" v-model="model.location" :rules="{required:true}"></validated-input>
                        </div>
                        <!--                        <div class="fl-x-br mt-1  mb-3">-->
                        <!--                            <btn text="Add" class=" px-4" type="reset"></btn>-->
                        <!--                        </div>-->
                    </div>
                    <div class="row mt-7 fl-x-br">
                        <div class="col-lg-6 pl-0">
                            <btn text="Save" :loading="loading" loading-text="Saving..." class="mr-4 px-5"></btn>
                        </div>
                        <div class="col-lg-6 fl-x-tr">
                            <btn text="Show Despatch"
                                 @click="ShowDispatch" class="mr-4 font-poppins-regular" type="reset"></btn>
<!--                            <btn text="Take Print" @click="downloadReportModal" :loading="downloading"-->
<!--                                 loading-text="Please Wait..."-->
<!--                                 design="basic-b" class="mr-4" type="reset"></btn>-->
                            <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                        </div>
                    </div>
                </div>
            </s-form>

            <modal ref="takePrint" title=" ">
                <div class="row">
                    <s-form @submit="downloadReport" class="col-12">

                        <div class="col-lg-12">
                            <validated-ajax-vue-select
                                :url="downloadOptionURL"
                                class="c-input-select text-black"
                                label="RSB" v-model="downloadVariable"></validated-ajax-vue-select>
                        </div>
                        <div class="col-12 text-right">
                            <btn text="Take Print" :loading="downloading"
                                 loading-text="Please Wait..."
                                 design="basic-b" type="submit"></btn>
                        </div>

                    </s-form>

                </div>

            </modal>
        </div>
    </div>

</template>

<script>
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'VirtualDespatchingTable',
    props: {
        sendModel: {
            type: Object
        }
    },
    data () {
        return {
            URL: urls.virtualDespatching.addEdit,
            loading: false,
            loadingData: false,
            downloading: false,
            model: {
                semen_quarantine: '',
                rsb_despatch: '',
                ptm: '',
                position: '',
                dose: '',
                container_no: '',
                location: ''

            },
            data: [],
            selectedItem: '',
            downloadVariable: '',
            downloadOptionURL: urls.virtualDespatching.RSBVUeSelect
        };
    },

    mounted () {
        this.loadData();
    },
    methods: {
        downloadReportModal () {
            this.$refs.takePrint.show();
        },
        clearData () {
            this.model.semen_quarantine = '';
            this.model.rsb_despatch = '';
            this.model.ptm = '';
            this.model.position = '';
            this.model.dose = '';
            this.model.container_no = '';
            this.model.location = '';
            this.$emit('clear');
            this.$refs.form.reset();
        },
        async loadData () {
            this.loadingData = true;
            const response = await axios.form(urls.virtualDespatching.DespatchList, this.sendModel);
            this.data = response.data.data;
            this.loadingData = false;
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        selectBull (data) {
            this.model.semen_quarantine = data.id;
            this.selectedItem = data;
        },
        clearSelected () {
            this.selectedItem = '';
        },
        async addItem () {
            try {
                this.loading = true;
                console.log(this.URL);
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.clearSelected();
                    await this.clearData();
                    await this.loadData();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async downloadReport () {
            try {
                const that = this;
                that.downloading = true;
                const response = await axios.get(urls.virtualDespatching.downloadReport + '?rsb_despatch=' + that.downloadVariable);
                const json = response.data;
                if (json.error === false) {
                    that.downloading = false;
                    window.open(json.url);
                } else {
                    that.downloading = false;
                    that.$notify('Something went wring..!', 'Error', { type: 'warning' });
                }
            } catch (error) {
                this.downloading = false;
                console.error('API request error:', error);
                this.$notify('Failed to download the report. Please try again.', 'Error', { type: 'warning' });
            }
        },
        ShowDispatch () {
            this.$router.push({ path: '/virtual-dispatching/details/' });
        }
    }
};
</script>

<style scoped>
table {
    background-color: #f3f3f3;
    text-align: center;
}

th {
    background-color: white;
    border: 0px;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-left: 18px;
    color: black;
}

td {
    padding-left: 18px;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: black;
}

table, td, tr {
    border: none;
    border-collapse: collapse;
    color: black;
}

table, th, tr {
    border-radius: 1px;

}
</style>
